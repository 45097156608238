/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';

import { ComponentGeneratorService } from '@services/component-generator/component-generator.service';
import { LanguageService } from '@services/language/language.service';
import { AuthService } from '@services/auth/auth.service';
import { SlickgridFormatterService } from '@services/slickgrid-formatter/slickgrid-formatter.service';
import { ConfigService } from '@services/config/config.service';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusCodes } from 'http-status-codes';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
    public login = '';
    public password = '';
    public displayError = false;
    public errorMsg = '';
    public personalDataLink: string = this._configService.personalDataLink;
    public email: string = this._configService.email;
    public phoneNumber: string = this._configService.phoneNumber;
    public faxNumber: string = this._configService.faxNumber;
    public webSSOAuthentication: boolean = this._configService.isWebSSOAuthentication;
    public isDisabled = false;
    public isPasswordVisible = false;
    public displayLDAP = !this.webSSOAuthentication;

    private readonly _subscriptions: Subscription = new Subscription();

    constructor(private readonly _authService: AuthService,
                private readonly _componentGeneratorService: ComponentGeneratorService,
                private readonly _languageService: LanguageService,
                private readonly _router: Router,
                private readonly _formatter: SlickgridFormatterService,
                private readonly _configService: ConfigService) {}

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public submit(): void {
        this.isDisabled = true;
        this._subscriptions.add(this._authService.authenticate(this.login, this.password).subscribe(() => {
            this._subscriptions.add(forkJoin([
                this._componentGeneratorService.initMenu(),
                this._languageService.loadFilterValuesTranslations(),
                this._formatter.initFormatter()
            ]).subscribe({complete: () => {
                    this._router.navigate(['/']);
                }
            }));
        }, (err: HttpErrorResponse) => {
            this.isDisabled = false;
            this.displayError = true;
            if (err?.error?.code === StatusCodes.FORBIDDEN) {
                this.errorMsg = '_LOG_._INVALID_CREDENTIALS_';
            } else {
                this.errorMsg = err?.error?.message ? err.error.message : '_LOG_._INVALID_CREDENTIALS_';
            }
        }));
    }

    public webSSOLogin() {
        location.assign(this._configService.authenticationUrl);
    }

    public togglePasswordVisibility(): void {
        this.isPasswordVisible = !this.isPasswordVisible;
    }
}
